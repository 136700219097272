import React, { useEffect, useState } from "react";
import AnimatedLogosvg from "./animated-logo-svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "gatsby";
import { isRunningOnWeb } from "../../functions/utils";

gsap.registerPlugin(ScrollTrigger);
let lastScrollTop: number = 0;
let animationRunning: boolean = false;
export function translateOnY() {

  const elem = document.getElementById("tobo-button");
  if (elem) {
    let st: number = isRunningOnWeb() ? window.pageYOffset : document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > lastScrollTop && !animationRunning) {
      elem.classList.add("translate-on-y");
      animationRunning = true
      setTimeout(() => {
        elem.classList.remove("translate-on-y")
        elem.classList.add("bounce-on-y")
        setTimeout(() => {
          elem.classList.remove("bounce-on-y")
          animationRunning = false;
        }, 1100)

      }, 1880)
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }
}

const AnimatedLogoBtn = ({ currentUrl }: any) => {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    // Commentato fino a definizione finale dell'animazione
    // window.addEventListener("scroll", translateOnY);
    // translateOnY();
  });
  return (
    <div id="tobo-button" className="bottomright">
      <button>       
        {/* {(currentUrl !== '/tobo-comics') && <Link to="/tobo-comics"><AnimatedLogosvg></AnimatedLogosvg></Link>} */}
         <a href="https://tobo.solar"><AnimatedLogosvg></AnimatedLogosvg></a>
      </button>
    </div>)
}

export default AnimatedLogoBtn;

    // to slow
    // ScrollTrigger.create({
    //   trigger: "#tobo-button",
    //   start: "0px top",
    //   end: 99999,
    //   toggleActions: "restart pause restart pause",
    //   onUpdate: ({ progress, direction, isActive }) => {
    //     console.log(direction);
    //     if (direction == 1) {
    //       elem.classList.add("translate-on-y");
    //       setTimeout(() => {
    //         elem.classList.remove("translate-on-y")
    //       }, 2000)
    //     }
    //   }
    // })